@font-face {
  font-family: 'Porsche Next Regular';
  src: url('https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-la-regular.min.b8f1c203bd2ac5334247025236ba8d86.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Porsche Next Semi Bold';
  src: url('https://cdn.ui.porsche.com/porsche-design-system/fonts/porsche-next-w-la-semi-bold.min.b5f6fca7225aa73578f65320bcf79b45.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Porsche Next Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #FFF;
}

h1 {
  font-family: 'Porsche Next Semi Bold', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  height: 100vh; /* Adjust height as needed */
  display: flex;
  justify-content: center; /* Centers child horizontally */
}


@keyframes blink {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.typing-dots span {
  animation: blink 1.4s infinite both;
  margin-left: 2px;
}

.typing-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

.logo {
  width: 100%;
  max-width: 100px;
  text-align: center;
}

.App-header {
  text-align: center;
}

.App-header img {
  padding: 10px;
}

.user-area {
  text-align: center;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.user-area p {
  margin: 0px;
}

.logout {
  color: #0092A6;
}
